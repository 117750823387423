<template>
  <nav class="nav">
    <ul class="nav-list space-around">
      <li class="nav-list__item">
        <router-link
          :to="{
            name: 'PointTransactions',
            query: {
              cardNo: this.$route.query.cardNo,
              phoneNumber: this.$route.query.phoneNumber,
              page: 1,
              pageSize: 500,
              startDate: startDate,
            },
          }"
          active-class="active"
          class="router-link"
        >
          使用記錄</router-link
        >
      </li>
      <li class="nav-list__item">
        <router-link
          :to="{
            name: 'EarnedPoint',
            query: {
              cardNo: this.$route.query.cardNo,
              phoneNumber: this.$route.query.phoneNumber,
              page: 1,
              pageSize: 500,
              startDate: earnedPointsStartDate,
            },
          }"
          active-class="active"
          class="router-link"
          >點數贈送</router-link
        >
      </li>
    </ul>
  </nav>
</template>
<script>
import dayjs from "dayjs";
export default {
  name: "PointNav",
  data() {
    return {
      startDate: "",
      earnedPointsStartDate: ""
    };
  },
  mounted() {
    const defaultDays = 30;
    this.getStartDate(defaultDays);
  },

  methods: {
    getStartDate(duration) {
      this.startDate = dayjs().subtract(duration, "Day").format("YYYY-MM-DD");
      this.earnedPointsStartDate = dayjs().subtract(5, "year").format("YYYY-MM-DD");
    },
  },
};
</script>
