<template>
  <main id="charge">
    <section class="section-top pb-1">
      <h1 class="section-top-title">U-POINT 查詢</h1>
      <p class="section-top-text">{{ this.$route.query.phoneNumber }}</p>
    </section>
    <PointNav />
    <router-view></router-view>
  </main>
</template>
<script>
import PointNav from "../components/UI/Nav/PointNav.vue";
export default {
  name: "PointResult",
  components: { PointNav },
};
</script>
